import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { compose, splitEvery } from 'ramda';

import { PageContent, PageSection } from 'components/layout';
import { HeaderPrefix } from 'components/header-prefix';
import { GoalSheet, GoalCard } from 'components/goals';
import { TopicsStore } from 'modules/topics';
import { useStoreQuery } from 'utils/state';
import { loginRequired } from 'modules/auth';
import { connectObserver } from 'utils/state';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  pageSection: {
    paddingBottom: 85,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 24,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  justifyRight: {
    justifyContent: 'flex-start',
  },
  goalCard: {
    marginRight: 12,
    '&:last-child': {
      marginRight: 0,
    },
  },
  fab: {
    backgroundColor: theme.palette.accent.main,
    position: 'absolute',
    bottom: 12,
    left: 12,
  }
}));

const decorate = compose(loginRequired, connectObserver(
  'topicsStore',
  'goalsStore',
));
export const TopicPage = decorate(({
  topicsStore,
  goalsStore,
  onStart,
  submitting,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { topicId, goalId } = useParams();
  const [ error, loading, topic ] = useStoreQuery(topicsStore, 'get', {
    args: topicId
  });
  const [state, setState] = React.useState({
    goalSelected: null,
    open: false,
  });

  const close = () => {
    setState(prev => ({ ...prev, open: false }));
    history.goBack();
  };
  const showGoal = async g => history.push(`${url}/goal/${g._id}`);
  const back = () => history.push('/topics');

  React.useEffect(() => {
    if (!goalId) { return; }
    (async () => {
      const goal = await goalsStore.get(goalId);
      setState(prev => ({ ...prev, goalSelected: goal, open: true }));
    })();
  }, [goalId]);

  return (
    <PageContent className={classes.root}>
      {
        loading && <div>Loading...</div>
      }
      {
        error && <div>ERROR</div>
      }
      {
        !loading && !error && topic && <>
          <Helmet>
            <title>Topic - { topic.name }</title>
          </Helmet>
          <Typography variant='h1' component='h1'>
            <HeaderPrefix>
              Choose a goal
            </HeaderPrefix>
            { topic.name }
          </Typography>
          <PageSection className={classes.pageSection}>
            {
              splitEvery(2, topic.goals).map((row, idx) => (
                <div key={idx} className={clsx(classes.row, {
                  [classes.justifyRight]: row.length < 2,
                })}>
                {
                  row.map(g => (
                    <GoalCard
                      key={g._id}
                      goal={g}
                      className={classes.goalCard}
                      backgroundColor='#507EDB'
                      onClick={() => showGoal(g)}
                    />
                  ))
                }
                </div>
              ))
            }
          </PageSection>
        </>
      }
      {
        state.goalSelected
          ? <GoalSheet
            open={state.open}
            goal={state.goalSelected}
            onStart={onStart}
            handleClose={close}
            disabled={submitting}
          />
          : null
      }
      <Fab className={classes.fab} aria-label='back' onClick={back}>
        <KeyboardArrowLeftIcon />
      </Fab>
    </PageContent>
  );
});

TopicPage.propTypes = {
  topicsStore: PropTypes.instanceOf(TopicsStore),
};
