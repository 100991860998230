
import * as $ from 'superagent';
import log from 'loglevel';
import QueryString from 'query-string';
import { without, path } from 'ramda';


export class HttpService {
  static DefaultHeaders = {
    'Accept': 'application/json; text/html'
  }

  _authToken = null;
  callbacks = {};

  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  set token(authToken) {
    this._authToken = authToken;
  }

  get token() {
    return this._authToken;
  }

  logout() {
    this.token = null;
  }

  async get(url, opts = {}, headers = {}) {
    const { query } = opts || {};
    const { payload } = opts || {};

    if (query) {
      const q = QueryString.stringify(query);
      if (q) { url += `?${q}`; }
    }

    return this.request('get', url, headers, payload);
  }

  async post(url, payload, headers = {}) {
    return this.request('post', url, headers, payload);
  }

  async put(url, payload, headers = {}) {
    return this.request('put', url, headers, payload);
  }

  async delete(url, headers = {}) {
    return this.request('delete', url, headers);
  }

  async request(method, url, headers, payload) {
    try {
      const request = $[method](`${this.baseUrl}${url}`)
        .set(Object.assign(this.defaultHeaders, headers));

      const response = await (payload ? request.send(payload) : request.send());
      this.handleResponseCallbacks(response);

      return response;
    } catch (error) {
      error.code = path(['response', 'body', 'code'], error);
      error.message = path(['response', 'body', 'message'], error);
      error && error.response && this.handleResponseCallbacks(error.response);
      throw error;
    }
  }

  handleResponseCallbacks(response) {
    try {
      const callbacks = this.callbacks[response.status];

      if (callbacks instanceof Array) {
        callbacks.forEach(handler => handler(response));
      }
    } catch (err) {
      log.error('HttpService#handleResponseCallbacks', err);
    }
  }

  on(statusCode, handler) {
    if (!this.callbacks[statusCode]) {
      this.callbacks[statusCode] = [];
    }
    this.callbacks[statusCode].push(handler);
  }

  off(statusCode, handler = null) {
    if (!(this.callbacks[statusCode] instanceof Array)) { return; }

    if (handler) {
      this.callbacks[statusCode] = without(handler, this.callbacks[statusCode]);
    } else {
      delete this.callbacks[statusCode];
    }
  }

  get defaultHeaders() {
    let authHeader = {};

    if (this.token) {
      authHeader = {
        'Authorization': `Bearer ${this.token}`
      };
    }

    return Object.assign({}, HttpService.DefaultHeaders, authHeader);
  }
}
