import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SwipeableViews from 'react-swipeable-views';
import moment from 'moment';
import queryString from 'query-string';
import * as ics from 'ics';
import { Helmet } from "react-helmet";
import { makeStyles } from '@material-ui/core/styles';
import {
  useParams, useHistory, useLocation, useRouteMatch
} from 'react-router-dom';
import {
  compose, find, includes, indexOf, propEq, sortBy, prop, filter, not, anyPass,
  length, path, assoc, isEmpty, last,
} from 'ramda';

import { PageContent, PageSection } from 'components/layout';
import { CoachingSessionPage } from './coaching-session.page';
import { HeaderPrefix } from 'components/header-prefix';
import { Tabs } from './components/coaching-tabs.component';
import { Tab } from './components/coaching-tab.component';
import { MediaTab } from './components/media.tab';
import { SessionsTab } from './components/sessions.tab';
import { NotebookTab } from './components/notebook.tab';
import { ShareCoachingBottomSheet } from './components/share-coaching-bottom-sheet';
import { BottomStickyControls } from 'components/bottom-sticky';
import { CoachingsStore } from 'modules/coachings';
import { loginRequired } from 'modules/auth';
import { connectObserver, useStoreQuery, useLoaders } from 'utils/state';
import { useSegment } from 'utils/analytics';

const TAB_IDS = ['sessions', 'media', 'notebook'];
const byOrder = sortBy(prop('order'));
const isCompleted = prop('dateCompleted');
const isSkipped = prop('dateSkipped');
const completedOrSkipped = anyPass([isCompleted, isSkipped]);
const notCompletedOrSkipped = compose(not, completedOrSkipped);
const sessionRemaining = compose(
  Boolean,
  length,
  filter(notCompletedOrSkipped)
);
const showCoachingShareDialog = assoc('showCoachingShareDialog');

const decorate = compose(loginRequired, connectObserver(
  'coachingsStore',
  'notebooksStore',
  'sharedCoachingStore',
  'teamsStore',
));

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,

    '&:last-child': {
      paddingBottom: 0,
    },
  },
  tabBar: {
  },
  swipeContainer: {
    overflow: 'auto',
    height: '100%',
    padding: '0 var(--page-padding) var(--page-padding) var(--page-padding)',
    boxSizing: 'border-box',
  },
  backButton: {
    backgroundImage: 'unset',
    backgroundColor: theme.palette.success.main,
    marginRight: 12,
  },
  stickyContainer: {
    paddingBottom: 95,
  },
}));

export const CoachingPage = decorate(({
  coachingsStore,
  notebooksStore,
  sharedCoachingStore,
  teamsStore
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();
  const { track } = useSegment();
  const { id, sessionId } = useParams();
  const [ cError, cLoading, coaching ] = useStoreQuery(coachingsStore, 'get', { args: id });
  const [ sError, sLoading, sharedCoaching ] = useStoreQuery(sharedCoachingStore, 'get');
  const [ tError, tLoading, teamCount ] = useStoreQuery(teamsStore, 'count');
  const [ nError, nLoading, notebook ] = useStoreQuery(notebooksStore, 'forCoaching', {
    args: id,
  });
  const { fullScreenLoader } = useLoaders([coaching, notebook]);
  const [state, setState] = React.useState({
    tabId: last(location.pathname.split('/')),
    session: null,
    notebookPage: null,
    open: Boolean(sessionId),
    loading: false,
    showCoachingShareDialog: false,
  });

  const search = queryString.parse(location.search) || {};

  const error = cError || nError || sError || tError;
  const loading = cLoading || nLoading || sLoading || tLoading;
  const toTab = tabId => {
    if (coaching) {
      track('Coaching Tab Changed', {
        category: 'Coachings',
        coaching: coaching.title,
        tab: tabId,
      });
    }
    setState(prev => ({ ...prev, tabId }));
  };
  const getUrl = tabId => {
    let newUrl = `${url}/${tabId}`;
    if (!isEmpty(search)) {
      newUrl += `?${queryString.stringify(search)}`;
    };
    if (location.hash) {
      newUrl += location.hash;
    }
    return newUrl;
  };
  const handleTabChange = (event, tabId) => toTab(tabId);
  const handleIndexChange = idx => toTab(TAB_IDS[idx]);
  const getIndex = () => Math.max(indexOf(state.tabId, TAB_IDS), 0);
  const disableActions = () => setState(prev => ({ ...prev, loading: true }));
  const enableActions = () => setState(prev => ({ ...prev, loading: false }));
  const closeDialog = () => {
    track('Coaching Session Closed', {
      category: 'Coachings',
      coaching: coaching.title,
      session: path(['title'], state.session),
    });
    history.push(`/coachings/${id}`);
  };
  const setReminder = (dateTime, session) => {
    const start = moment(dateTime).format('YYYY-M-D-H-m').split("-");
    const event = ics.createEvent({
      start,
      duration: { minutes: 15 },
      title: `Coaching Session: ${session.title}`,
      busyStatus: 'BUSY',
      status: 'CONFIRMED',
      description: `
To become your best self, you are currently working to "${coaching.title}".

Follow the link below to access your coaching session.

${window.location.href}?source=calendar
      `
    }
    );
    const url = `data:text/calendar;charset=utf8,${encodeURIComponent(event.value)}`;
    const anchor = document.createElement('a');
    anchor.href = `${url}.ics`;
    anchor.download = 'bestselfy-event.ics';
    anchor.click();

    track('Coaching Session Scheduled', {
      category: 'Coachings',
      coaching: coaching.title,
      session: session.title,
    });
  };
  const selectSession = session => {
    history.push(`/coachings/${id}/sessions/${session._id}`);
    track('Coaching Session Opened', {
      category: 'Coachings',
      coaching: coaching.title,
      session: session.title,
    });
  };
  
  const completeSession = async session => {
    session.dateSkipped = null;
    session.dateCompleted = moment.utc();
    disableActions();
    await coachingsStore.save(coaching);
    enableActions();
    closeDialog();

    track('Coaching Session Completed', {
      category: 'Coachings',
      coaching: coaching.title,
      session: session.title,
      rating: session.rating,
    });
  };
  const skipSession = async session => {
    session.dateSkipped = moment.utc();
    disableActions();
    await coachingsStore.save(coaching);
    enableActions();
    closeDialog();

    track('Coaching Session Skipped', {
      category: 'Coachings',
      coaching: coaching.title,
      session: session.title,
    });
  };
  const unskipSession = async session => {
    session.dateSkipped = null;
    disableActions();
    await coachingsStore.save(coaching);
    enableActions();
    track('Coaching Session Unskipped', {
      category: 'Coachings',
      coaching: coaching.title,
      session: session.title,
    });
  };
  const completeCoaching = async () => {
    coaching.dateFinished = moment.utc();
    disableActions();
    await coachingsStore.save(coaching);
    await sharedCoachingStore.reset();
    enableActions();
    history.push(`/coachings/${id}/completed`);

    track('Coaching Completed', {
      category: 'Coachings',
      coaching: coaching.title,
      rating: coaching.rating,
    });
  };
  const saveNotebookPage = async page => {
    await notebooksStore.savePage(page);
  };
  const shareCoaching = async reason => {
    if (!sharedCoaching) { return; }
    disableActions();
    await sharedCoachingStore.share(coaching, reason);
    enableActions();
    setState(prev => ({ ...prev, showCoachingShareDialog: false }));
    track('Coaching Shared', {
      category: 'Coachings',
      coaching: coaching.title,
    });
  };
  const toDashboard = () => history.push('/dashboard');

  React.useEffect(() => {
    if (!(coaching && notebook)) { return; }
    switch (location.pathname) {
      case `${url}/sessions`:
        toTab('sessions');
        break;
      case `${url}/media`:
        toTab('media');
        break;
      case `${url}/notebook`:
        toTab('notebook');
        break;
      default:
        // noop
    }
  }, [coaching, notebook]);

  React.useLayoutEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const elem = document.querySelector(location.hash);
        elem?.scrollIntoView(true);
      }, 500);
    }
  }, [location.hash]);

  React.useEffect(() => {
    if (!includes(state.tabId, TAB_IDS)) {
      toTab('sessions');
      history.push(getUrl('sessions'));
    } else {
      history.push(getUrl(state.tabId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tabId]);

  React.useEffect(() => {
    if (!sharedCoaching || (teamCount || 0) < 1) { return; }
    if (!sharedCoaching.coaching) {
      setState(showCoachingShareDialog(true));
    }
  }, [sharedCoaching, teamCount]);

  React.useEffect(() => {
    if (!(coaching && notebook)) { return; }

    if (sessionId) {
      const session = find(propEq('_id', sessionId), coaching.sessions);
      const notebookPage = find(
        p => p.session._id === session._id,
        notebook.pages
      );
      setState(prev => ({ ...prev, open: true, session, notebookPage }));
    } else {
      setState(prev => ({ ...prev, open: false }));
    }
  }, [sessionId, coaching, notebook]);

  React.useEffect(() => {
    if (!(search.source && coaching)) { return; }
    (async () => {
      track('Coaching Opened From Outside Source', {
        category: 'Coachings',
        coaching: coaching.title,
        source: search.source,
      });
      delete search.source;
      const query = queryString.stringify(search);
      history.replace(`${url}${query ? `?${query}` : ''}`);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.source, coaching]);

  const swipableProps = {
    index: getIndex(),
    onChangeIndex: handleIndexChange,
  };

  return <>
    <PageContent>
      {
        fullScreenLoader
      }
      {
        error && <div>ERROR</div>
      }
      {
        !loading && !error && coaching && <>
          <Helmet>
            <title>Coaching</title>
          </Helmet>

          <Typography variant='h1' component='h1'>
            <HeaderPrefix>Coaching</HeaderPrefix>
            {
              coaching.title
            }
          </Typography>

          <PageSection className={classes.root} variant='card'>
            <div className={classes.tabBar}>
              <Tabs className={classes.tabs} value={state.tabId} onChange={handleTabChange}>
                <Tab value='sessions' label='Sessions' />
                <Tab value='media' label='Media' />
                <Tab value='notebook' label='Notebook' />
              </Tabs>
            </div>
            
            <SwipeableViews { ...swipableProps }>
              <div className={classes.swipeContainer}>
                <SessionsTab
                  className={classes.stickyContainer}
                  sessions={byOrder(coaching.sessions)}
                  onSelect={selectSession}
                  onCompleteCoaching={completeCoaching}
                />
              </div>
              
              <div className={classes.swipeContainer}>
                <MediaTab media={coaching.media} />
              </div>
              
              <div className={classes.swipeContainer}>
                <NotebookTab notebook={notebook} sessions={coaching.sessions} />
              </div>
            </SwipeableViews>

            {
              state.tabId === 'sessions'
                ? <BottomStickyControls>
                    <Button
                      size='large'
                      className={classes.backButton}
                      variant='contained'
                      onClick={toDashboard}
                      disabled={!!state.loading}
                    >
                      <KeyboardBackspaceIcon />
                    </Button>
                    <Button
                      size='large'
                      className={classes.button}
                      variant='contained'
                      onClick={completeCoaching}
                      disabled={Boolean(sessionRemaining(coaching.sessions) || loading || coaching.dateFinished)}
                      fullWidth
                    >
                      {
                        coaching.dateFinished
                          ? 'Completed'
                          : 'Finish Coaching'
                      }
                    </Button>
                  </BottomStickyControls>
                : null
            }
          </PageSection>
        </>
      }
      <CoachingSessionPage
        open={Boolean(state.open && state.session)}
        onClose={closeDialog}
        coaching={coaching}
        session={state.session}
        notebookPage={state.notebookPage}
        onSetReminder={setReminder}
        onSkipSession={skipSession}
        onUnskipSession={unskipSession}
        onBack={closeDialog}
        onCompleteSession={completeSession}
        onNotesChange={saveNotebookPage}
        actionsDisabled={Boolean(state.loading || state.session?.dateCompleted)}
      />

      <ShareCoachingBottomSheet
        open={Boolean(state.showCoachingShareDialog)}
        onClose={() => setState(showCoachingShareDialog(false))}
        onShareCoaching={shareCoaching}
        disabled={Boolean(state.loading)}
      />
    </PageContent>
  </>;
});

CoachingPage.propTypes = {
  coachingsStore: PropTypes.instanceOf(CoachingsStore),
};
