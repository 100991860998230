import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import DoneIcon from '@material-ui/icons/Done';
import { Typography, CircularProgress, Button, makeStyles, Box } from '@material-ui/core';

import { ColorHeaderCard } from 'components/cards';
import { PlaceholderCard } from './placeholder.card';
import { connectObserver, useStoreQuery } from 'utils/state';

const PROGRESS_RAD = 72;

const decorate = connectObserver(
  'selfDiscoveryAnswersStore',
  'selfDiscoveryQuestionsStore',
);

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  progressContainer: {
    position: 'relative',
    height: `${PROGRESS_RAD}px`,
  },
  progressBottom: {
    color: theme.palette.yellow[50],
  },
  progressTop: {
    position: 'absolute',
    left: 0,
  },
  progressLabel: {
    color: theme.palette.accent.main,
    position: 'absolute',
    top: 0, left: 0, right: 0, bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  progressLabelText: {
    fontSize: '1.25rem',
  },
  progressLabelIcon: {
    color: theme.palette.success.main,
    margin: 'auto',
    width: `calc(${PROGRESS_RAD} * 0.7px)`,
    height: 'auto',
  },
  text: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  circle: {
    color: theme.palette.accent.main,
    strokeLinecap: 'round',
  },
  circleDone: {
    color: theme.palette.success.main,
  },
  centerVertically: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const SelfDiscoverySection = decorate(({
  selfDiscoveryAnswersStore,
  selfDiscoveryQuestionsStore,
}) => {
  let component = null;
  const history = useHistory();
  const classes = useStyles();
  const [aError, aLoading, answers] = useStoreQuery(
    selfDiscoveryAnswersStore, 'fetch'
  );
  const [qError, qLoading, questions] = useStoreQuery(
    selfDiscoveryQuestionsStore, 'fetch',
  );

  const loading = qLoading || aLoading;
  const error = qError || aError;
  const progress = Math.ceil(answers?.length / questions?.length * 100);

  const handleClick = () => {
    history.push('/self-discovery');
  };

  if (!error && !loading && answers?.length === 0) {
    component = (
      <div className={classes.root}>
        <Typography variant='h2' component='h2'>
          Self Discovery
        </Typography>
        <PlaceholderCard
          title='10 minute activity'
          text='Reflect on your collaboration style and how you want to grow.'
          ctaUrl='/self-discovery'
        />
      </div>
    );
  } else if (!error && !loading && answers?.length > 0) {
    component = (
      <div className={classes.root}>
        <Typography variant='h2' component='h2'>
          Self Discovery
        </Typography>
        <ColorHeaderCard
          headerTitle='Guided self-reflection'
        >
          <div className={classes.content}>
            <div className={classes.centerVertically}>
              <div className={classes.progressContainer}>
                <CircularProgress
                  className={classes.progressBottom}
                  variant='determinate'
                  size={PROGRESS_RAD}
                  thickness={4}
                  value={100}
                />

                <CircularProgress
                  className={classes.progressTop}
                  classes={{
                    root: clsx({
                      [classes.circle]: progress < 100,
                      [classes.circleDone]: progress >= 100,
                    })
                  }}
                  variant={progress > 0 ? 'determinate' : 'indeterminate'}
                  size={PROGRESS_RAD}
                  thickness={4}
                  value={progress}
                />

                <Box className={classes.progressLabel}>

                  {
                    progress < 100
                      ? <Typography className={classes.progressLabelText} variant='h4'>
                        {progress}%
                      </Typography>
                      : <DoneIcon className={classes.progressLabelIcon} />
                  }
                </Box>
              </div>
            </div>
            <div className={classes.text}>
              <Typography variant='body1' component='div'>
                {answers?.length} of {questions?.length} questions answered
              </Typography>
            </div>
          </div>
          <Button size='small' variant='contained' color='primary' fullWidth onClick={handleClick}>
            {
              progress < 100 ? 'Continue' : 'Edit'
            }
          </Button>
        </ColorHeaderCard>
      </div>
    );
  }

  return component;
});
