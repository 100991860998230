import React from 'react';
import log from 'loglevel';
import * as Sentry from '@sentry/react';
import { useParams, useHistory } from 'react-router-dom';
import { compose, pick, pathOr } from 'ramda';

import { TopicsPage } from './topics.page';
import { TopicPage } from './topic.page';
import { loginRequired } from 'modules/auth';
import { connectObserver } from 'utils/state';
import { createTodoList } from 'utils/todos';
import { useSegment } from 'utils/analytics';

const decorate = compose(loginRequired, connectObserver(
  'coachingsStore',
  'goalsStore',
));

export const TopicsControllerPage = decorate(({
  coachingsStore,
  goalsStore,
}) => {
  const history = useHistory();
  const { topicId } = useParams();
  const { track } = useSegment();
  const [state, setState] = React.useState({
    submitting: false,
  });

  const onStart = async (focusArea, goal) => {
    setState(prev => ({ ...prev, submitting: true }));
    try {
      const coachingData = {
        ...createTodoList(focusArea),
        goal: pick(['_id', 'title'], goal),
        focusArea: pick(['_id', 'title', 'description', 'learning'], focusArea),
      };

      const newCoaching = await coachingsStore.create(
        coachingsStore.newItem,
        coachingData
      );

      if (newCoaching instanceof Error) {
        log.error('TopicsPage#onStart', newCoaching);
      } else {
        track('Coaching Created', {
          category: 'Coachings',
          goal: pathOr(null, ['title'], goal),
          goalId: pathOr(null, ['_id'], goal),
          focusArea: pathOr(null, ['title'], focusArea),
          focusAreaId: pathOr(null, ['_id'], focusArea),
          coaching: pathOr(null, ['title'], newCoaching),
          coachingId: pathOr(null, ['_id'], newCoaching),
        });
        goalsStore.resetRecommendationCache();
        history.push(`/coachings/${newCoaching._id}`);
      }
    } catch (err) {
      log.error('TopicsPage#onStart', err);
      Sentry.captureException(err);
    }

  };

  if (topicId) {
    return <TopicPage onStart={onStart} submitting={state.submitting} />;
  } else {
    return <TopicsPage onStart={onStart} submitting={state.submitting} />;
  }
});