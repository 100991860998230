import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'ramda';

import { BottomSheet } from 'components/bottom-sheet';
import { PageContent, PageSection } from 'components/layout';
import { SubHeader } from 'components/sub-header';
import { BasicInformation } from './components/basic-information.component';
import { NotificationSettings } from './components/notifications.component';
import { SecuritySettings } from './components/security.component';
import { loginRequired } from 'modules/auth';
import { connectObserver, useStoreQuery } from 'utils/state';
import { useSegment } from 'utils/analytics';

const Alert = props => <MuiAlert elevation={6} variant='filled' {...props} />;

const decorate = compose(loginRequired, connectObserver(
  'subscriptionStore'
));

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.paper.light,
    backgroundImage: 'none',
    color: theme.palette.text.dark,
    marginBottom: 16,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  subscribeButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.light,
  },
  snackbar: {
    width: '90%',
  },
}));

export const AccountPage = decorate(({
  subscriptionStore,
}) => {
  useStoreQuery(subscriptionStore, 'get');

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { track } = useSegment();
  const { url } = useRouteMatch();
  const [ state, setState ] = React.useState({
    component: null,
    title: null,
    showSnackbar: false,
  });

  const subscription = subscriptionStore.item$;
  const showSnackbar = () => setState(prev => ({ ...prev, showSnackbar: true }));
  const hideSnackbar = () => setState(prev => ({ ...prev, showSnackbar: false }));

  React.useEffect(() => {
    let title;
    let component;
    switch(location.pathname) {
      case `${url}/basic-information`:
        title = 'Basic Information';
        component = <BasicInformation onSave={closeBottomSheet} />;
        break;
      case `${url}/notifications`:
        title = 'Notifications';
        component = <NotificationSettings />;
        break;
      case `${url}/security`:
        title = 'Security';
        component = <SecuritySettings />;
        break;
      default:
        title = null;
        component = null;
    }
    setState(prev => ({ ...prev, title, component }));
  }, [location.pathname]);

  const to = url => () => history.push(url);
  const closeBottomSheet = () => history.push(url);
  const openPortal = () => {
    showSnackbar();
    const cb = window.Chargebee.getInstance();
    cb.setPortalSession(() => subscriptionStore.openPortal());
    const portal = cb.createChargebeePortal();
    portal.open({
      loaded: function () {
        track('Chargebee Portal Closed');
      },
      close: function () {
        track('Chargebee Portal Closed');
      },
      visit: function (sectionName) {
        track('Chargebee Portal Section Visited', { sectionName });
      },
      paymentSourceAdd: function () {
        track('Chargebee Payment Source Added');
      },
      paymentSourceUpdate: function () {
        track('Chargebee Payment Source Updated');
      },
      paymentSourceRemove: function () {
        track('Chargebee Payment Source Removed');
      },
      subscriptionChanged: function ({ subscription: { id } }) {
        track('Chargebee Subscription Changed', { id });
      },
      subscriptionCustomFieldsChanged: function ({ subscription: { id } }) {
        track('Chargebee Subscription Custom Fields Changed', { id });
      },
      subscriptionCancelled: function ({ subscription: { id } }) {
        track('Chargebee Subscription Cancelled', { id });
      },
      subscriptionPaused: function ({ subscription: { id } }) {
        track('Chargebee Subscription Paused', { id });
      },
      subscriptionResumed: function ({ subscription: { id } }) {
        track('Chargebee Subscription Resumed', { id });
      },
      scheduledPauseRemoved: function ({ subscription: { id } }) {
        track('Chargebee Subscription Pause Removed', { id });
      },
      subscriptionReactivated: function ({ subscription: { id } }) {
        track('Chargebee Subscription Reactivated', { id });
      }
    });
  };

  return (
    <PageContent>
      <Helmet>
        <title>{
          state.title
            ? `Account - ${state.title}`
            : 'Account'
        }</title>
      </Helmet>
      <Typography variant='h1' component='h1'>Account</Typography>
      <SubHeader>
          You can change your personal information, password, email address
          and the way bestselfy notifies you about important events
        </SubHeader>
      <PageSection>
        <Button
          className={classes.button}
          variant='contained'
          size='large'
          onClick={to(`${url}/basic-information`)}
          fullWidth
        >
          Basic Information
        </Button>

        <Button
          className={classes.button}
          variant='contained'
          size='large'
          onClick={to(`${url}/notifications`)}
          fullWidth
        >
          Notifications
        </Button>

        <Button
          className={classes.button}
          variant='contained'
          size='large'
          onClick={to(`${url}/security`)}
          fullWidth
        >
          Security
        </Button>

        {
          subscription
            ? <Button
                className={classes.button}
                variant='contained'
                size='large'
                onClick={openPortal}
                fullWidth
              >
                Manage Subscription
              </Button>
            : <Button
                className={clsx(classes.button, classes.subscribeButton)}
                variant='contained'
                size='large'
                onClick={to('/subscription/plans')}
                fullWidth
              >
                Subscription Plans
              </Button>
        }

        <BottomSheet
          title={<Typography variant='h1'>{state.title}</Typography>}
          open={Boolean(state.component)}
          bottomSticky={false}
          content={state.component}
          actions={null}
          handleClose={closeBottomSheet}
        />

        <Snackbar
          open={state.showSnackbar}
          autoHideDuration={600000}
          onClose={hideSnackbar}
        >
          <Alert className={classes.snackbar} onClose={hideSnackbar} severity='info'>
            Please make sure to allow pop-ups for this page in order to manage
            your subscription.
          </Alert>
        </Snackbar>
      </PageSection>
    </PageContent>
  );
});
