import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { compose } from 'ramda';

import { PageContent, PageSection } from 'components/layout';
import { BottomStickyControls } from 'components/bottom-sticky';
import { ManagerProfile } from './components/manager.profile';
import { EmployeeProfile } from './components/employee.profile';
import { loginRequired } from 'modules/auth';
import { connectObserver, useStoreQuery, useLoaders } from 'utils/state';
import { AccountStore } from 'modules/account';
import { CoachingsStore } from 'modules/coachings';
import { HeaderPrefix } from 'components/header-prefix';

const useStyles = makeStyles(theme => ({
  root: {
    gap: 24,
  },
}));

const decorate = compose(loginRequired, connectObserver(
  'accountStore',
  'coachingsStore',
));

export const ProfilePage = decorate(({
  accountStore,
  coachingsStore,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [aError, aLoading, acc] = useStoreQuery(accountStore, 'get');
  const [cError, cLoading, coachings] = useStoreQuery(coachingsStore, 'count');
  const { fullScreenLoader } = useLoaders([acc, coachings]);

  const hasCoachings = coachings?.count > 0;

  const next = () => hasCoachings
    ?  history.push('/dashboard')
    : history.push('/topics');

  return <>
    <PageContent>
      {
        fullScreenLoader
      }
      <Helmet>
        <title>Profile</title>
      </Helmet>

      <Typography variant='h1' component='h1'>
        <HeaderPrefix>Profile</HeaderPrefix>
        {accountStore.name$}
      </Typography>

      <PageSection variant='card' className={classes.root} bottomSticky>
        {
          acc?.isManager
            ? <ManagerProfile />
            : <EmployeeProfile />
        }
        <BottomStickyControls>
          <Button fullWidth size='large' variant='contained' color='primary' onClick={next}>
            {
              hasCoachings ? 'Continue coaching' : 'Start coaching'
            }
          </Button>
        </BottomStickyControls>
      </PageSection>
    </PageContent>
  </>;
});

ProfilePage.propTypes = {
  accountStore: PropTypes.instanceOf(AccountStore),
  coachingsStore: PropTypes.instanceOf(CoachingsStore),
};
