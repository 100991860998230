import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& a': {
      textDecoration: 'underline',
      fontWeight: 'normal',
      color: theme.palette.success.main,
    },
    '& img': {
      maxWidth: '100%',
      padding: '24px 0',
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const parseHtml = htmlParser({
  isValidNode: node => node.type !== 'script',
});

export const Markdown = ({
  orientation = 'column',
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <ReactMarkdown
      className={clsx('ReactMarkdown', classes.root, classes[orientation], className)}
      linkTarget='_blank'
      { ...props }
      escapeHtml={false}
      astPlugins={[parseHtml]}
      disallowedTypes={['heading']}
      unwrapDisallowed={true}
    />
  );
};

Markdown.propTypes = {
  orientation: PropTypes.string,
  className: PropTypes.string,
};
