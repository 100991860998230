import * as log from 'loglevel';
import React from 'react';
import PropTypes from 'proptypes';
import queryString from 'query-string';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet';
import { inject } from 'mobx-react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useForm, useFormState } from 'react-hook-form';
import { pathOr, curry } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

import { PageContent } from 'components/layout';
import { AuthService } from 'modules/auth';
import { AccountStore } from 'modules/account';
import { useSegment } from 'utils/analytics';
import { wrapRegister } from 'utils/forms';

const getError = curry((errors, field) => pathOr(null, [field, 'message'], errors));

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.main,

    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  signUpLink: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '1.43',
    letterSpacing: '0.2px',
    textAlign: 'center',
    color: '#6f7faf',
    marginTop: theme.spacing(2),
  },
}));

const LoginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const LoginPage = inject(
  'authService',
  'accountStore',
)(({
  authService,
  accountStore,
  loginSuccessRedirect,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { track, identify } = useSegment();
  const { register, handleSubmit, control, setError, clearErrors } = useForm({
    resolver: yupResolver(LoginSchema),
  });
  const { errors } = useFormState({ control });
  const [ state, setState ] = React.useState({
    submitting: false,
  });
  const errorFor = getError(errors);
  const reg = wrapRegister(register);
  const search = queryString.parse(location.search) || {};
  const signUpLink = search.redirect
    ? `/signup?redirect=${encodeURIComponent(search.redirect)}`
    : '/signup';

  async function onLogin({ email, password }) {
    clearErrors();
    setState(prev => ({ ...prev, submitting: true }));
    await authService.logout();
    try {
      const user = await authService.login(email, password);
      onLoginSuccess(user);
    } catch (err) {
      await onLoginFailure(err);
      setState(prev => ({ ...prev, submitting: false }));
    }
  }

  async function onLoginSuccess(user) {
    const account = await accountStore.get();
    let redirect = search.redirect || loginSuccessRedirect;
    if (pathOr(true, ['onboarding', 'showWelcomeScreen'], account)) {
      redirect = '/welcome';
    }
    log.debug('LoginPage#onLoginSuccess redirect', decodeURIComponent(redirect));
    identify(user);
    track('User Login', { category: 'Users' });
    history.push(redirect);
  }

  function onLoginFailure(err) {
    log.debug('LoginPageController#onLoginFailure', err);
    let name = '__all__';
    let message = 'Unknown error';

    if (err.code === 'auth/invalid-email') {
      name = 'email';
      message = 'This email address is invalid';
    } else if (err.code === 'auth/user-disabled') {
      name = 'email';
      message = 'This user account has been disabled';
    } else if (err.code === 'auth/user-not-found') {
      name = 'email';
      message = 'This email address is invalid';
    } else if (err.code === 'auth/wrong-password') {
      name = 'password';
      message = 'Wrong password';
    } else if (err.code === 'auth/too-many-requests') {
      name = 'password';
      message = 'Too many unsuccessful login attempts. Please try again later.';
    }

    setError(name, { type: '', message });
  }

  return (
    <PageContent variant='backgroundPage'>
      <Helmet>
        <title>Log In</title>
      </Helmet>
      <Typography variant='h1' component='h1'>Sign in to bestselfy.</Typography>
      <Typography paragraph>
        Be your best self at work.
      </Typography>
      <form className='' onSubmit={handleSubmit(onLogin)}>
        <TextField className={classes.textField} label='Email'
          type='text' name='email' variant='outlined'
          error={Boolean(errorFor('email'))}
          helperText={errorFor('email')} { ...reg('email') }
        />
        <TextField className={classes.textField} label='Password'
          type='password' name='password' variant='outlined'
          error={Boolean(errorFor('password'))}
          helperText={errorFor('password')} { ...reg('password') }
        />
        <Button fullWidth size='large' variant='contained' type='submit'
          disabled={state.submitting}
        >
          {state.submitting ? 'Logging in...': 'Login'}
        </Button>
        <Typography className={classes.signUpLink}>
          Don&#39;t have an account?&nbsp;
          <Link className='OutboundLink' to={signUpLink}>Register</Link>
        </Typography>
        <Typography className={classes.signUpLink}>
          Forgot your password?&nbsp;
          <Link className='OutboundLink' to='/password-recovery'>Retrieve it</Link>
        </Typography>
      </form>
    </PageContent>
  );
});

LoginPage.propTypes = {
  authService: PropTypes.instanceOf(AuthService),
  accountStore: PropTypes.instanceOf(AccountStore),
  loginSuccessRedirect: PropTypes.string,
};

LoginPage.defaultProps = {
  loginSuccessRedirect: '/'
};

export {
  LoginPage,
};
