import React from 'react';
import Typography from '@material-ui/core/Typography';
import MuiDialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const Dialog = withStyles({
  root: {
    maxWidth: 'var(--max-content-width)',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
  },
})(MuiDialog);

const DialogContent = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  }
})(MuiDialogContent);

export const NoteDialog = withStyles(theme => ({
  notes: {
    flexGrow: 1,
    width: '100%',
    outline: 'none',
    border: 'unset',
    backgroundColor: 'unset',
    color: theme.palette.text.dark,
    font: '400 1em Poppins',
    resize: 'none',
    padding: 0,
    margin: 0,
    boxSizing: 'border-box',
    appearance: 'none',
    opacity: 1,
    '&:focus': {
      outline: 'none',
    }
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 4,
    minWidth: 'unset',
  },
  title: {
    paddingRight: 16,
  },
}))(({
  classes,
  open = false,
  title = null,
  text = '',
  onClose = () => {},
}) => {
  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={onClose}
      fullScreen
    >
      <DialogContent className={classes.content}>
        <Button className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </Button>
        {
          title
            ? <Typography variant='h2' component='h2' className={classes.title}>
                {
                  title
                }
              </Typography>
            : null
        }
        {
          <textarea
            className={classes.notes}
            value={text || 'No notes...'}
            disabled={true}
          />
        }
      </DialogContent>
    </Dialog>
  );
});