import React from 'react';
import queryString from 'query-string';
import { Redirect, useLocation } from 'react-router-dom';

import { ResetPasswordPage } from './reset-password.page';
import { EmailVerificationPage } from './email-verification.page';

export const AuthActionPage = () => {
  const location = useLocation();
  const search = queryString.parse(location.search) || {};

  let page;
  let message;

  switch (search.mode) {
    case 'resetPassword':
      page = <ResetPasswordPage />;
      break;
    case 'recoverEmail':
      message = 'Your old email address was successfully restored, you can now safely close this page';
      page = <EmailVerificationPage successMessage={message} />;
      break;
    case 'verifyAndChangeEmail':
    case 'verifyEmail':
      page = <EmailVerificationPage />;
      break;
    default:
      page = <Redirect to='/404' />;
  }

  return page;
};
