import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import { Helmet } from 'react-helmet';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link, useParams, useHistory } from 'react-router-dom';
import { compose, assoc } from 'ramda';

import { PageContent, PageSection } from 'components/layout';
import { ReadMore } from 'components/read-more';
import { CoachingProgress } from 'components/progress';
import { loginRequired } from 'modules/auth';
import { connectObserver, useStoreQuery, useLoaders } from 'utils/state';
import { useSegment } from 'utils/analytics';
import { BottomStickyControls } from 'components/bottom-sticky';
import { isMobile } from 'utils/client';

import { CoachingNudgeList } from './components/coaching-nudge-list.component';
import { NudgeMenu } from './components/nudge-menu.component';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const menuOpen = assoc('menuOpen');
const nudgeClicked = assoc('nudgeClicked');
const showCopySnackbar = assoc('showCopySnackbar');
const showRatingSnackbar = assoc('showRatingSnackbar');
const hideSnackbar = compose(
  assoc('showCopySnackbar', false),
  assoc('showRatingSnackbar', false)
);

const Alert = props => <MuiAlert elevation={6} variant='filled' {...props} />;

const LargeAvatar = withStyles({
  root: {
    width: 65,
    height: 65,
    fontSize: '1.5rem',
  },
})(Avatar);

const TeamMemberHeader = withStyles(theme => ({
  title: {
    marginBottom: theme.spacing(0),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(({ initials, name, email, role, avatar, classes }) => (
  <Box display='flex' mb={3}>
    <Box display='flex' flexDirection='column' alignItems='center' mr={2}>
      <LargeAvatar src={avatar}>{initials}</LargeAvatar>
    </Box>
    <Box display='flex' flexDirection='column' justifyContent='center'>
      <Typography className={classes.title} variant='h2' component='h1'>
        {name || email}
      </Typography>
      <Typography component='div'>{role || 'Team Member'}</Typography>
    </Box>
  </Box>
));

const CoachingOverview = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    padding: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))(({
  classes,
  coaching,
}) => (
  <Box className={classes.root}>
    <Typography variant='h4' component='h2'>
      { coaching.title }
    </Typography>
    <CoachingProgress coaching={coaching} />
    <Typography variant='body1' component='div'>
      {coaching.description}
    </Typography>
  </Box>
));

const CoachingReason = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}))(({
  classes,
  firstName,
  reason,
}) => (
  <Box className={classes.root}>
    <Typography variant='h4' component='h3'>
      {firstName}&apos;s coaching goal
    </Typography>
    {
      reason
        ? <ReadMore variant='body1' component='div'>{reason}</ReadMore>
        : <Typography variant='body1' component='div'>
            <i>
              This team member did not offer a reason for starting this coaching.
            </i>
          </Typography>
    }
  </Box>
));

const Explanation = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  ul: {
    paddingInlineStart: 20,
    marginBlockStart: '0.25em',
    marginBlockEnd: '0.25em',
  },
}))(({
  memberName = 'your team member',
  classes,
}) => (
  <Card className={classes.root} elevation={0}>
    <CardContent className={classes.content}>
      <Box display='flex'>
        <Box mr={1}><InfoIcon /></Box>
        <Box>
          <Typography variant='body1' component='div'>
            This is a conversation starter to set off a chat with {memberName}:
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography variant='body1' component='span'>
                Click on a nudge
              </Typography>
            </li>
            <li>
              <Typography variant='body1' component='span'>
                Select "Copy nudge text"
              </Typography>
            </li>
            <li>
              <Typography variant='body1' component='span'>
                Paste nudge into your chat or email
              </Typography>
            </li>
            <li>
              <Typography variant='body1' component='span'>
                Once coaching conversation is done, click on nudge and select
                “Complete coaching conversation”
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    </CardContent>
  </Card>
));

const decorate = compose(loginRequired, connectObserver(
  'teamsStore',
  'completedCoachingNudgesStore',
));

const useStyles = makeStyles(theme => ({
  emptyQuestions: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  nudges: {
    marginBottom: theme.spacing(2),
  },
}));

export const TeamMemberPage = decorate(({
  teamsStore,
  completedCoachingNudgesStore,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { track } = useSegment();
  const { teamId, memberId } = useParams();
  const [ state, setState ] = React.useState({
    menuOpen: false,
    nudgeClicked: null,
    showCopySnackbar: false,
    showRatingSnackbar: false,
  });

  const [ error, loading, team ] = useStoreQuery(teamsStore, 'get', {
    args: [teamId],
  });

  const member = React.useMemo(() => {
    if (!team) { return null; }
    return team.members.find(m => m._id === memberId);
  }, [team]);

  const { initials, name, email, role, avatar } = React.useMemo(() => {
    if (!member) { return {}; }
    const { firstName, lastName, user, role, avatar } = member.account;
    const name = (firstName && lastName)
      ? `${firstName} ${lastName}`
      : user.email;
    const initials = (firstName && lastName)
      ? `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
      : user.email.charAt(0).toUpperCase();
    return { name, initials, email: user.email, role, avatar };
  }, [member]);

  const { fullscreenLoader } = useLoaders([team]);

  const handleOpenMenu = () => setState(menuOpen(true));
  const handleCloseMenu = () => setState(menuOpen(false));
  const handleNudgeClicked = nudge => setState(nudgeClicked(nudge));
  const handleShowCopySnackbar = () => setState(showCopySnackbar(true));
  const handleShowRatingSnackbar = () => setState(showRatingSnackbar(true));
  const handleHideSnackbar = () => setState(hideSnackbar);
  const handleNudgeClick = nudge => {
    handleNudgeClicked(nudge);
    handleOpenMenu();
  };
  const handleOpenSlack = nudge => {
    handleCopyText(nudge);
    setTimeout(() => {
      if (isMobile(navigator.userAgent)) {
        window.open('slack://open', '_blank');
      } else {
        window.open('https://slack.com', '_blank');
      }
    });
    track('Slack Opened For Nudging', {
      team: teamId,
      member: memberId,
      nudge: nudge._id,
    });
  };
  const handleCopyText = nudge => {
    navigator.clipboard.writeText(nudge.text || '');
    handleShowCopySnackbar();
    handleCloseMenu();
    track('Nudge Text Copied', {
      team: teamId,
      member: memberId,
      nudge: nudge._id,
    });
  };
  const handleRateInteraction = async (nudge, rating) => {
    const coachingId = member.sharedCoaching?.coaching?._id;
    const coacheeId = member.account._id;
    handleCloseMenu();
    handleShowRatingSnackbar();
    if (member?.sharedCoaching?.nudges) {
      member.sharedCoaching.nudges = member.sharedCoaching.nudges.filter(
        n => n.nudge._id !== nudge._id
      );
    }
    await completedCoachingNudgesStore.complete(nudge, rating, coacheeId, coachingId);
    track('Nudge Interaction Rated', {
      team: teamId,
      member: memberId,
      nudge: nudge._id,
      rating,
    });
  };

  return (
    <PageContent className={classes.root}>
      {fullscreenLoader}
      <Helmet>
        <title>{name || email || 'Team Member'}</title>
      </Helmet>
      <TeamMemberHeader initials={initials} name={name} email={email} role={role} avatar={avatar} />
      <PageSection variant='card' bottomSticky>
        {
          !error && !loading && member && <>
          {
            member.sharedCoaching?.coaching
              ? <Box mb={4}>
                  <CoachingOverview coaching={member.sharedCoaching.coaching} />
                  <CoachingReason
                    firstName={member?.account?.firstName || 'Your Team Member'}
                    reason={member.sharedCoaching.reason}
                  />
                </Box>
              : <Box mb={4} textAlign='center'>
                  <Typography>
                    <i>This team member didn't share any coachings</i>
                  </Typography>
                </Box>
          }
          </>
        }

        <Typography variant='h3' component='h3'>
          Coaching Nudges
        </Typography>
        <Typography variant='body1' component='div'>
          Follow up with these suggestions.
        </Typography>

        {
          (member?.sharedCoaching?.nudges || []).length > 0
            ? <>
                <Box mt={2}>
                  <CoachingNudgeList
                    entries={member.sharedCoaching.nudges}
                    onClick={handleNudgeClick}
                    className={classes.nudges}
                  />
                </Box>
                <Explanation memberName={member?.account?.firstName} />
              </>
            : <Box className={classes.emptyQuestions} mt={2}>
                No nudges yet.
              </Box>
        }

        <Box display='flex' justifyContent='flex-end' mt={4}>
          <Link to={`/teams/${teamId}/members/${memberId}/archived-nudges`}>
            <Typography variant='body1' component='span'>
              <b>Archived Nudges</b>
            </Typography>
          </Link>
          <KeyboardArrowRight />
        </Box>
      </PageSection>
      <BottomStickyControls>
        <Button
          fullWidth
          variant='contained'
          size='large'
          color='primary'
          onClick={() => history.push(`/teams/${teamId}`)}
        >
          Show Team
        </Button>
      </BottomStickyControls>

      <Snackbar
        open={state.showCopySnackbar}
        autoHideDuration={3000}
        onClose={handleHideSnackbar}
      >
        <Alert className={classes.snackbar} onClose={handleHideSnackbar} severity='info'>
          Nudge text copied
        </Alert>
      </Snackbar>

      <Snackbar
        open={state.showRatingSnackbar}
        autoHideDuration={3000}
        onClose={handleHideSnackbar}
      >
        <Alert className={classes.snackbar} onClose={handleHideSnackbar} severity='info'>
          Nudge rated and archived
        </Alert>
      </Snackbar>

      <NudgeMenu
        open={state.menuOpen}
        nudge={state.nudgeClicked}
        memberName={member?.account?.firstName}
        onOpenSlack={handleOpenSlack}
        onCopyText={handleCopyText}
        onRateInteraction={handleRateInteraction}
        onClose={handleCloseMenu}
      />
    </PageContent>
  );
});
