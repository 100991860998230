import * as log from 'loglevel';
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { inject } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { PageContent } from 'components/layout';
import { AuthService } from 'modules/auth';
import { RegistrationForm } from 'components/registration-form';
import { useSegment } from 'utils/analytics';

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: theme.spacing(2),
  },
}));

export const RegistrationPage = inject(
  'authService',
)(({
  authService,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const { identify, track } = useSegment();
  const [ formDisabled, setFormDisabled ] = React.useState(false);
  const [ formError, setFormError ] = React.useState(null);
  const query = queryString.parse(search);

  const onSubmit = async (credentials) => {
    setFormError(null);
    setFormDisabled(true);

    try {
      const timezone = moment.tz.guess();
      const { user } = await authService.register({
        ...credentials,
        timezone
      });

      identify(user);
      track('Account Created', {
        category: 'Users',
        couponCode: decodeURIComponent(query.coupon || ''),
        preset: decodeURIComponent(query.preset || ''),
        company: decodeURIComponent(query.company || ''),
      });

      query.redirect
        ? history.push(query.redirect)
        : history.push('/welcome');
    } catch(error) {
      log.debug('RegistrationPage#onSubmit', error);
      let name = '__all__';
      let message = 'Unknown error';

      if (error.code === 'auth/email-already-in-use') {
        name = 'email';
        message = 'This email address is already in use';
      } else if (error.code === 'auth/invalid-email') {
        name = 'email';
        message = 'This is not a valid email address';
      } else if (error.code === 'auth/weak-password') {
        name = 'password';
        message = 'This password is too weak, please choose another one';
      }

      setFormError({ name, message });
      setFormDisabled(false);
    }
  };

  return (
    <PageContent variant='backgroundPage'>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <Typography className={classes.header} variant='h1' component='h1'>
        Create your account
      </Typography>
      <Typography className={classes.text}>
        Building self-awareness, learning stuff that was never taught in school,
        and navigating the workplace is tough!
      </Typography>
      <Typography className={classes.text}>
        Ready to launch your coaching journey and level up in less than 10
        minutes per day?
      </Typography>
      <RegistrationForm
        disabled={formDisabled}
        onSubmit={onSubmit}
        formError={formError}
      />
    </PageContent>
  );
});
RegistrationPage.propTypes = {
  authService: PropTypes.instanceOf(AuthService),
};
