import React from 'react';
import PropTypes from 'prop-types';
import MuiFab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import clsx from 'clsx';
import queryString from 'query-string';
import { pathOr, find, propEq } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import { MoodTrackerDialog } from './mood-tracker-dialog.component';
import { Emoji } from 'components/emoji';
import { TrackerStore } from 'modules/tracker';
import { useStoreQuery, connectObserver } from 'utils/state';
import { DAILY_FEEDBACK } from 'utils/constants';
import { getFeedbackForDay } from 'utils/tracker';
import { useSegment } from 'utils/analytics';
import { varyByOs, OS } from 'utils/client';

const Fab = (props) => <MuiFab disableRipple {...props} />;

const bg = 'linear-gradient(90deg, rgba(112, 89, 205, 0.75), rgba(45, 89, 209, 0.75))';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 18,
    backgroundColor: 'unset',
    color: theme.palette.text.light,
    '&:hover': {
      backgroundColor: 'unset',
      color: theme.palette.text.light,
    },
    '@media (max-width: 480px)': {
      right: 18,
    },

    '@media (min-width: 481px)': {
      right: 'calc(50% - 0.5 * var(--max-content-width) + 18px)',
    }
  },
  fabLabel: {
    fontSize: varyByOs({
      [OS.WINDOWS]: 30,
      [OS.MAC]: 40,
      [OS.IOS]: 40,
      [OS.ANDROID]: 35,
    }, 30),
  },
  fabBackground: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundImage: bg,
    borderRadius: 100,
  },
  emoji: {
    isolation: 'isolate',
    marginTop: varyByOs({
      [OS.WINDOWS]: 3,
      [OS.MAC]: 3,
      [OS.IOS]: 1,
      [OS.ANDROID]: -2,
    }, -5),
  },
}));

export const MoodTracker = connectObserver(
  'trackerStore',
)(({
  trackerStore,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const { url } = useRouteMatch();
  const { track } = useSegment();
  const [error, loading] = useStoreQuery(trackerStore, 'fetch');
  const [state, setState] = React.useState({
    open: false,
  });
  const days = trackerStore.items$;
  const today = getFeedbackForDay(moment.utc(), days || []);
  const emoji = pathOr(null, ['feedback', 'emoticon'], today);
  // const openCalendar = () => history.push('/tracker');
  const search = queryString.parse(location.search) || {};

  React.useEffect(() => {
    if (!search.mood || loading) { return; }
    (async () => {
      const feedback = find(propEq('label', search.mood), DAILY_FEEDBACK);
      if (feedback) {
        await setFeedback(feedback);
      }
      delete search.mood;
      const query = queryString.stringify(search);
      history.replace(`${url}${query ? `?${query}` : ''}`);
    })();
  }, [search.mood, loading]);

  const setFeedback = async df => {
    const day = today || trackerStore.newItem;
    day.feedback = { emoticon: df.symbol, value: df.label };
    if (today) {
      await trackerStore.save(day); // Yes, the tracker store saves the day...
      track('Daily Feedback Changed', {
        category: 'MoodTracker',
        title: df.label,
      });
    } else {
      await trackerStore.create({}, day);
      track('Daily Feedback Created', {
        category: 'MoodTracker',
        title: df.label,
      });
    }
    hide();
  };
  const show = () => setState(prev => ({ ...prev, open: true }));
  const hide = () => setState(prev => ({ ...prev, open: false }));

  if (error) {
    return null;
  }

  return (
    <>
      <MoodTrackerDialog open={state.open} onClose={hide} onSelectMood={setFeedback} />
      <Fab className={classes.root} onClick={show}>
        {
          loading
            ? <CircularProgress color='primary' />
            : null
        }
        {
          !loading && days && <>
            <div className={classes.fabBackground} />
          {
              emoji
                ? <Emoji className={clsx(classes.emoji, classes.fabLabel)} symbol={emoji} />
                : <Emoji className={clsx(classes.emoji, classes.fabLabel)} symbol='😶' />
          }
          </>
        }
      </Fab>
    </>
  );
});

MoodTracker.propTypes = {
  trackerStore: PropTypes.instanceOf(TrackerStore),
};