import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { findIndex, keys, fromPairs } from 'ramda';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { ScaleQuestionField, MultipleChoiceQuestionField } from './fields';
import { preventDefault } from 'utils/forms';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 16,
    boxSizing: 'border-box',
    overflow: 'auto',
  },
  stepper: {
    background: 'var(--white)',
  },
  stepperContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
  }
}));

const components = {
  scale: ScaleQuestionField,
  multipleChoice: MultipleChoiceQuestionField
};

export const AssessmentAnswerForm = ({
  questions,
  onSubmit,
  submitting,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const indices = {
    scale: 0,
    multipleChoice: 0,
  };
  const form = React.useRef();
  const [ answers, setAnswers ] = React.useState(fromPairs(questions.map(q =>
    [q._id, null]
  )));
  const [ activeStep, setActiveStep ] = React.useState(0);

  const handleChange = qId => event => {
    const { value } = event.target;
    setAnswers(prevState => ({ ...prevState, ...{ [qId]: value } }));
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleSubmit = preventDefault(() => {
    onSubmit(answers);
  });

  React.useEffect(() => {
    const idx = findIndex(q => answers[q._id] === null, questions);
    handleStepChange(idx >= 0 ? idx : questions.length);
  }, [answers]);

  const maxSteps = questions.length + 1;
  const activeStepId = keys(answers)[activeStep];
  const hasNext = answers[activeStepId] !== null;
  const getAnswer = questionId => answers[questionId];

  return (
    <form ref={form} className='AssessmentAnswerForm' onSubmit={handleSubmit}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        className={classes.content}
        onChangeIndex={handleStepChange}
        disabled={true}
        enableMouseEvents
      >
        {questions.map((q, index) => {
          const Component = components[q.type];
          const name = `${q.type}[${indices[q.type]}]`;
          indices[q.type] += 1;

          return (
            <div className={classes.stepperContent} key={index}>
              <Component
                question={q}
                onChange={handleChange(q._id)}
                answer={getAnswer(q._id)}
                name={name}
              />
            </div>
          );
        })}
        <div className={classes.stepperContent}>
          <Typography paragraph={true}>
            You’re done!
            Ready to see your results?
          </Typography>
          <Button type='submit' variant='contained' disabled={submitting}>
            Go to results
          </Button>
        </div>
      </SwipeableViews>

      <MobileStepper
        className={classes.stepper}
        steps={maxSteps}
        position='static'
        variant='progress'
        activeStep={activeStep}
        nextButton={
          <Button size='small' onClick={handleNext} disabled={!hasNext}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </form>
  );
};

AssessmentAnswerForm.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string
    })).isRequired
  })).isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

AssessmentAnswerForm.defaultProps = {
  onSubmit: () => {},
  submitting: false,
};
