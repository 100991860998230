import { TodoListsStore } from './todo-lists.store';

export class TodoListsArchiveStore extends TodoListsStore {
  baseUrl = '/todos/lists/archive';
  error = new Error('TodoListsArchiveStore is read-only');

  async create() { throw this.error; }
  async save() { throw this.error; }
  async delete() { throw this.error; }
}
