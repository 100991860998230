import React from 'react';
import PropTypes from 'prop-types';

export const CalendarIcon = ({
  width = '20',
  height = '21',
  viewBox = '0 0 20 21',
  fill = '##0547CC',
  ...props
}) => (
  <svg { ...props } xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox}>
    <path fill={fill} d='M17 1c0-.552-.447-1-1-1-.553 0-1 .448-1 1v2c0 .552.447 1 1 1 .553 0 1-.448 1-1V1zM5 3c0 .552-.447 1-1 1-.553 0-1-.448-1-1V1c0-.552.447-1 1-1 .553 0 1 .448 1 1v2zm13 5v10H2V8h16zm2-6h-2v1c0 1.103-.897 2-2 2s-2-.897-2-2V2H6v1c0 1.103-.897 2-2 2s-2-.897-2-2V2H0v18h20V2zm4 3v19H2v-2h20V5h2zM7 12H5v-2h2v2zm4 0H9v-2h2v2zm4 0h-2v-2h2v2zm-8 4H5v-2h2v2zm4 0H9v-2h2v2zm4 0h-2v-2h2v2z'/>
  </svg>

);

CalendarIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
};
