import React from 'react';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { compose } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { assoc } from 'ramda';
import { useHistory, useLocation } from 'react-router-dom';
import { pathOr } from 'ramda';

import { PageContent, PageSection } from 'components/layout';
import { SubHeader } from 'components/sub-header';
import { loginRequired } from 'modules/auth';
import { connectObserver, useStoreQuery, useLoaders } from 'utils/state';
import { useSegment } from 'utils/analytics';
import { guard } from 'utils/decorators';
import { TeamCard } from './components/team-card.component';

const setSaving = assoc('saving');
const decorate = compose(loginRequired, connectObserver(
  'teamsStore',
  'accountStore',
));

const useStyles = makeStyles(theme => ({
  root: {},
}));

export const JoinTeamPage = decorate(({
  teamsStore,
  accountStore,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const { track } = useSegment();
  const [ state, setState ] = React.useState({
    saving: false,
  });
  const [iError, iLoading, teams] = useStoreQuery(teamsStore, 'fetchInvitations', {
    args: id,
  });
  const [aError, aLoading, acc] = useStoreQuery(accountStore, 'get');

  const error = iError || aError;
  const loading = iLoading || aLoading;
  const { fullscreenLoader } = useLoaders([iLoading, aLoading]);
  const search = queryString.parse(location.search) || {};

  const joinTeam = async team => {
    setState(setSaving(true));
    guard(async () => {
      await teamsStore.joinTeam(team, search.invitation);
      setState(setSaving(false));
      track('Team Invitation Accepted', { category: 'Teams', teamId: team._id });
      if (pathOr(true, ['onboarding', 'showWelcomeScreen'], acc)) {
        history.push('/welcome');
      } else {
        history.push('/teams');
      }
    });
  };

  return (
    <PageContent className={classes.root}>
      {fullscreenLoader}
      <Helmet>
        <title>Team Invitations</title>
      </Helmet>
      <Typography variant='h1' component='h1'>
        Team Invitations
      </Typography>
      <SubHeader>
        Browse your open team invitations.
      </SubHeader>
      <PageSection variant='card'>
        {
          !loading && !error && teams && <>
            <div className={classes.pageSection}>
              {
                teams.map(team => (
                  <TeamCard
                    key={team._id}
                    className={classes.card}
                    team={team}
                    ctaLabel='Join Team'
                    onClickCta={joinTeam}
                    disabled={state.saving}
                  />
                ))
              }
              {
                teams.length === 0
                  ? <Typography>
                      You have no open team invitations.
                    </Typography>
                  : null
              }
            </div>
          </>
        }
      </PageSection>
    </PageContent>
  );
});