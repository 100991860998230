import 'mobx-react-lite/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppWrapper } from './app-wrapper';
import log from 'loglevel';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import packageJson from '../package.json';

import { AssessmentsStore, AssessmentAnswersStore } from 'modules/assessments';
import { TopicsStore } from 'modules/topics';
import { TodoListsStore, TodoListsArchiveStore } from 'modules/todos';
import { ClassificationsStore } from 'modules/classifications';
import { AuthService } from 'modules/auth';
import { HttpService } from 'modules/http';
import { FeedbackStore } from 'modules/feedback';
import { TrackerStore } from 'modules/tracker';
import { MenuStore } from 'modules/menu';
import { AccountStore } from 'modules/account';
import { SettingsStore } from 'modules/settings';
import { InvitationTokensStore } from 'modules/invitation-tokens';
import { GoalsStore } from 'modules/goals';
import { FocusAreasStore } from 'modules/focus-areas';
import { AssessmentConfigurationStore } from 'modules/cms';
import { FeatureFocusStore } from 'modules/feature-focus';
import { NotebooksStore } from 'modules/notebooks';
import { TeamsStore } from 'modules/teams';
import { CompletedCoachingNudgesStore } from 'modules/coaching-nudges';
import {
  CoachingsStore,
  SharedCoachingStore,
} from 'modules/coachings';
import {
  ManagerDiscoveryQuestionsStore,
  ManagerDiscoveryAnswersStore,
} from 'modules/manager-discovery';
import {
  SelfDiscoveryQuestionsStore,
  SelfDiscoveryAnswersStore,
} from 'modules/self-discovery';
import {
  SubscriptionStore,
  SubscriptionPlansStore 
} from 'modules/subscriptions';

import './index.css';

log.setLevel(process.env.REACT_APP_LOGLEVEL || log.levels.SILENT);

const httpService = new HttpService(process.env.REACT_APP_API_URL);
const authService = new AuthService({ httpService });
const assessmentsStore = new AssessmentsStore({ httpService });
const topicsStore = new TopicsStore({ httpService });
const classificationsStore = new ClassificationsStore({ httpService });
const todoListsStore = new TodoListsStore({ httpService, authService });
const coachingsStore = new CoachingsStore({ httpService, authService });
const todoListArchiveStore = new TodoListsArchiveStore({ httpService, authService });
const notebooksStore = new NotebooksStore({ httpService, authService });
const assessmentAnswersStore = new AssessmentAnswersStore({ httpService });
const feedbackStore = new FeedbackStore({ httpService });
const trackerStore = new TrackerStore({ httpService, authService });
const menuStore = new MenuStore();
const accountStore = new AccountStore({ httpService });
const settingsStore = new SettingsStore({ httpService });
const invitationTokensStore = new InvitationTokensStore({ httpService });
const goalsStore = new GoalsStore({ httpService });
const focusAreasStore = new FocusAreasStore({ httpService });
const assessmentConfigurationStore = new AssessmentConfigurationStore({ httpService });
const featureFocusStore = new FeatureFocusStore();
const subscriptionPlansStore = new SubscriptionPlansStore({ httpService });
const subscriptionStore = new SubscriptionStore({ httpService });
const managerDiscoveryQuestionsStore = new ManagerDiscoveryQuestionsStore({ httpService });
const managerDiscoveryAnswersStore = new ManagerDiscoveryAnswersStore({ httpService, authService });
const selfDiscoveryQuestionsStore = new SelfDiscoveryQuestionsStore({ httpService });
const selfDiscoveryAnswersStore = new SelfDiscoveryAnswersStore({ httpService, authService });
const teamsStore = new TeamsStore({ httpService });
const sharedCoachingStore = new SharedCoachingStore({ httpService });
const completedCoachingNudgesStore = new CompletedCoachingNudgesStore({ httpService });

const services = {
  authService,
  httpService,
  assessmentsStore,
  topicsStore,
  assessmentAnswersStore,
  todoListsStore,
  todoListArchiveStore,
  feedbackStore,
  trackerStore,
  menuStore,
  accountStore,
  settingsStore,
  invitationTokensStore,
  classificationsStore,
  goalsStore,
  focusAreasStore,
  assessmentConfigurationStore,
  featureFocusStore,
  subscriptionPlansStore,
  subscriptionStore,
  coachingsStore,
  notebooksStore,
  managerDiscoveryQuestionsStore,
  managerDiscoveryAnswersStore,
  selfDiscoveryQuestionsStore,
  selfDiscoveryAnswersStore,
  teamsStore,
  sharedCoachingStore,
  completedCoachingNudgesStore,
};

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: 'canopy@' + packageJson.version,
  });
}

try {
  window.Chargebee.init({
    site: process.env.REACT_APP_CHARGEBEE_SITE,
  });
} catch (err) {
  Sentry.captureException(err);
}

authService.isAuthenticated.then(() => {
  ReactDOM.render(
    <AppWrapper { ...services } />,
    document.getElementById('root'),
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
