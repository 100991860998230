import React from 'react';
import PropTypes from 'prop-types';
import HomeIcon from '@material-ui/icons/Home';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/Settings';
import StarIcon from '@material-ui/icons/Star';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { map, compose, filter, propEq } from 'ramda';

import { TrackerPage } from './pages/tracker';
import { CalendarIcon } from 'components/icons';
import { AssessmentResultPage } from './pages/classifications';
import { AnswerAssessmentPage } from './pages/assessments';
import { AppPage } from 'components/layout';
import { TopicsControllerPage } from './pages/topics';
import { AccountPage } from './pages/account';
import { DashboardPage } from './pages/dashboard';
import { ProfilePage } from './pages/profile';
import {
  TeamsPage, TeamPage, JoinTeamPage, TeamMemberPage,
 } from './pages/teams';
import { ManagerDiscoveryPage } from './pages/manager-discovery';
import { SelfDiscoveryPage } from './pages/self-discovery';
import {
  CoachingPage,
  CoachingCompletionPage,
} from './pages/coachings';
import { RecommendationsPage } from './pages/recommendations';
import {
  SubscriptionPlansPage,
  SubscriptionSuccessPage,
} from './pages/subscriptions';
import { ArchivedNudgesPage } from './pages/coaching-nudges';
import { AppHeaderComponent as AppHeader } from 'components/app-header';
import { BurgerMenu } from 'components/burger-menu';
import { PageTracker } from 'components/analytics';
import { AuthService } from 'modules/auth';
import { HttpService } from 'modules/http';
import { AssessmentConfigurationStore } from 'modules/cms';
import { SubscriptionStore } from 'modules/subscriptions';
import { connectObserver, useStoreQuery } from 'utils/state';
import { loginRequired } from 'modules/auth';
import { featuredIn } from 'utils/cms';

const featuredInMenu = featuredIn('menu');

const MenuLink = withStyles(theme => ({
  disabled: {
    color: theme.palette.text.disabled,
    cursor: 'not-allowed',
  },
}))(({ to, children, disabled, classes }) => (
  disabled
    ? <Typography className={classes.disabled}>{children}</Typography>
    : <Link to={to}>{children}</Link>
));
MenuLink.propTypes = {
  to: PropTypes.string,
  disabled: PropTypes.bool,
};

const decorate = compose(loginRequired, connectObserver(
  'httpService',
  'assessmentConfigurationStore',
  'subscriptionStore',
));

export const App = decorate(({
  httpService,
  assessmentConfigurationStore,
  subscriptionStore,
}) => {
  const history = useHistory();
  useStoreQuery(assessmentConfigurationStore, 'fetch');
  useStoreQuery(subscriptionStore, 'get');

  const assessmentConfigs = (assessmentConfigurationStore.items$ || []);
  const subscription = subscriptionStore.item$;

  
  const createMenuEntry = (url, icon) => config => {
    const f = featuredInMenu(config);
    return {
      icon,
      item: (
        <Link to={`${url}/${config.assessment}`}>
          { f.name }
        </Link>
      ),
    };
  };
  const createMenuEntries = (configs, url, type, icon) => compose(
    map(createMenuEntry(url, icon)),
    filter(featuredInMenu),
    filter(propEq('type', type)),
  )(configs);

  React.useEffect(() => {
    const handler401 = () => history.push('/logout');
    const handler402 = () => history.push('/subscription/plans');
    httpService.on(401, handler401);
    httpService.on(402, handler402);
    return () => {
      httpService.off(401, handler401);
      httpService.off(402, handler402);
    };
  }, [history, httpService]);

  return (
    <AppPage>
      <AppHeader />
      <PageTracker />
      <BurgerMenu  items={[
        { icon: <HomeIcon fill='#fff' />, item: <MenuLink to='/dashboard'>Dashboard</MenuLink> },
        { icon: <PersonIcon fill='#fff' />, item: <MenuLink to='/profile' >Profile</MenuLink> },
        { icon: <GroupIcon fill='#fff' />, item: <MenuLink to='/teams' >Teams</MenuLink> },
        { icon: <StarIcon fill='#fff' />, item: <MenuLink to='/topics'>New Coaching</MenuLink> },
        ...createMenuEntries(assessmentConfigs, '/assessments', 'assessment', <AssessmentIcon fill='#fff' />),
        { icon: <CalendarIcon fill='#fff' />, item: <MenuLink to='/tracker' disabled={!subscription}>Mood Tracker</MenuLink> },
        { icon: <SettingsIcon fill='#fff' />, item: <MenuLink to='/account'>Account</MenuLink> },
        { icon: <PowerSettingsNewIcon fill='#fff' />, item: <MenuLink to='/logout'>Sign Out</MenuLink> },
        { icon: <MailIcon fill='#fff' />, item: <a href='mailto:support@bestselfy.com'>Get In Touch</a>},
      ]} />
      <Switch>
        <Route exact path='/'>
          <Redirect to='/dashboard' />
        </Route>

        <Route exact path='/dashboard'>
          <DashboardPage />
        </Route>
        <Route exact path='/dashboard/:goalId'>
          <DashboardPage />
        </Route>

        <Route exact path='/profile'>
          <ProfilePage />
        </Route>

        <Route exact path='/topics'>
          <TopicsControllerPage />
        </Route>
        <Route exact path='/topics/goal/:goalId'>
          <TopicsControllerPage />
        </Route>
        <Route exact path='/topics/goal/:goalId/focus-area/:faId'>
          <TopicsControllerPage />
        </Route>
        <Route exact path='/topics/:topicId'>
          <TopicsControllerPage />
        </Route>
        <Route exact path='/topics/:topicId/goal/:goalId'>
          <TopicsControllerPage />
        </Route>

        <Route exact path='/coachings/:id/sessions/:sessionId'>
          <CoachingPage />
        </Route>
        <Route exact path='/coachings/:id/completed'>
          <CoachingCompletionPage />
        </Route>
        <Route path='/coachings/:id'>
          <CoachingPage />
        </Route>

        <Route exact path='/teams'>
          <TeamsPage />
        </Route>
        <Route exact path='/teams/:id'>
          <TeamPage />
        </Route>
        <Route exact path='/teams/:teamId/members/:memberId'>
          <TeamMemberPage />
        </Route>
        <Route exact path='/teams/:teamId/members/:memberId/archived-nudges'>
          <ArchivedNudgesPage />
        </Route>
        <Route exact path='/teams/join/:id'>
          <JoinTeamPage />
        </Route>

        <Route exact path='/manager-discovery'>
          <ManagerDiscoveryPage />
        </Route>
        <Route exact path='/self-discovery'>
          <SelfDiscoveryPage />
        </Route>
        <Route exact path='/assessments/:id'>
          <AnswerAssessmentPage />
        </Route>
        <Route exact path='/assessments/result/:answerId'>
          <AssessmentResultPage />
        </Route>
        <Route exact path='/recommendations/:assessmentId'>
          <RecommendationsPage />
        </Route>

        <Route path='/account'>
          <AccountPage />
        </Route>

        <Route exact path='/tracker'>
          <TrackerPage />
        </Route>

        <Route exact path='/subscription/plans'>
          <SubscriptionPlansPage />
        </Route>

        <Route exact path='/subscription/success'>
          <SubscriptionSuccessPage />
        </Route>

        <Route>
          <Redirect to='/404' />
        </Route>
      </Switch>
    </AppPage>
  );
});

App.propTypes = {
  authService: PropTypes.instanceOf(AuthService),
  httpService: PropTypes.instanceOf(HttpService),
  assessmentConfigurationStore: PropTypes.instanceOf(AssessmentConfigurationStore),
  subscriptionStore: PropTypes.instanceOf(SubscriptionStore),
};
