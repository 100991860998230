import React from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider, observer } from 'mobx-react';
import { useErrorBoundary } from 'use-error-boundary';

import { App } from './app';

import { SegmentProvider } from 'modules/analytics';
import { Page404 } from './app/pages/404';
import { LoginPage, LogoutPage, PasswordRecoveryPage, AuthActionPage } from './app/pages/auth';
import { GlobalErrorPage } from './app/pages/errors';
import { RegistrationPage } from './app/pages/registration';
import { WelcomePage } from './app/pages/onboarding';
import { ScrollTop } from 'components/utils';
import { theme } from 'theme/base';

import { AssessmentsStore, AssessmentAnswersStore } from 'modules/assessments';
import { TopicsStore } from 'modules/topics';
import { AuthService } from 'modules/auth';
import { HttpService } from 'modules/http';

import './App.css';

export const AppWrapper = observer((services) => {
  const { ErrorBoundary } = useErrorBoundary();

  return (
    <Router>
      <ScrollTop />
      <ErrorBoundary renderError={({ error }) => <GlobalErrorPage error={error} />}>
        <Provider { ...services }>
          <SegmentProvider apiKey={process.env.REACT_APP_SEGMENT_WRITE_KEY}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <ThemeProvider theme={theme}>
                <Switch>
                  <Route exact path='/login'>
                    <LoginPage />
                  </Route>
                  <Route exact path='/logout'>
                    <LogoutPage />
                  </Route>
                  <Route exact path='/signup'>
                    <RegistrationPage />
                  </Route>
                  <Route exact path='/password-recovery'>
                    <PasswordRecoveryPage />
                  </Route>

                  <Route exact path='/auth/action'>
                    <AuthActionPage />
                  </Route>

                  <Route path='/welcome'>
                    <WelcomePage />
                  </Route>

                  <Route path='/404'>
                    <Page404 />
                  </Route>

                  <Route path='/error'>
                    <GlobalErrorPage />
                  </Route>

                  <Route path='/' component={App} />
                  <Route>
                    <Page404 />
                  </Route>
                </Switch>
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </SegmentProvider>
        </Provider>
      </ErrorBoundary>
    </Router>
  );
});

AppWrapper.propTypes = {
  httpService: PropTypes.instanceOf(HttpService).isRequired,
  authService: PropTypes.instanceOf(AuthService).isRequired,
  assessmentsStore: PropTypes.instanceOf(AssessmentsStore).isRequired,
  topicsStore: PropTypes.instanceOf(TopicsStore).isRequired,
  assessmentAnswersStore: PropTypes.instanceOf(AssessmentAnswersStore).isRequired,
};
