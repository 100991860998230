import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Helmet } from 'react-helmet';
import { compose } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

import { PageContent, PageSection } from 'components/layout';
import { TeamCard } from './components/team-card.component';
import { TeamCardAdminMenu } from './components/team-card-admin-menu.component';
import { TeamCardMemberMenu } from './components/team-card-member-menu.component';
import { loginRequired } from 'modules/auth';
import { guard } from 'utils/decorators';
import { connectObserver, useStoreQuery, useLoaders } from 'utils/state';
import { useSegment } from 'utils/analytics';
import { sortAndGroupByMembership } from 'utils/teams';

const decorate = compose(loginRequired, connectObserver(
  'teamsStore',
  'accountStore',
));

const useStyles = makeStyles(theme => ({
  root: {},
  pageSection: {
    marginBottom: 32,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  card: {
    marginBottom: theme.spacing(2),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  addButton: {
    marginBottom: theme.spacing(2),
  },
}));

export const TeamsPage = decorate(({
  teamsStore,
  accountStore,
}) => {
  const classes = useStyles();
  const history = useHistory();
  
  const { track } = useSegment();
  const [ tError, tLoading ] = useStoreQuery(teamsStore, 'fetch');
  const [ aError, aLoading, acc ] = useStoreQuery(accountStore, 'get');
  const [state, setState] = React.useState({
    adminMenuOpen: false,
    memberMenuOpen: false,
    menuFor: null,
    saving: false,
  });

  const teams = acc && teamsStore.items$
    ? sortAndGroupByMembership(acc)(teamsStore.items$)
    : [];

  const { fullscreenLoader } = useLoaders([teamsStore.items$, acc]);

  const createNewTeam = async () => {
    const team = await teamsStore.createTeam('New Team');
    track('Team Created', { category: 'Teams', teamId: team._id });
  };

  const showAdminDialog = team => () => setState(prev => ({
    ...prev,
    adminMenuOpen: true,
    menuFor: team
  }));

  const closeAdminDialog = () => setState(prev => ({
    ...prev,
    adminMenuOpen: false,
    menuFor: null,
  }));

  const showMemberDialog = team => () => setState(prev => ({
    ...prev,
    memberMenuOpen: true,
    menuFor: team
  }));

  const closeMemberDialog = () => setState(prev => ({
    ...prev,
    memberMenuOpen: false,
    menuFor: null,
  }));

  const deleteTeam = async team => {
    setState({ ...state, saving: true });
    guard(async () => await teamsStore.delete(team));
    setState({ ...state, adminMenuOpen: false, saving: false });
    track('Team Deleted', { category: 'Teams', teamId: team._id });
  };

  const leaveTeam = async team => {
    setState({ ...state, saving: true });
    guard(async () => {
      const member = team.members.find(m => m.account._id === acc._id);
      if (member) {
        await teamsStore.leaveTeam(team);
      }
      track('Member Left Team', { category: 'Teams', teamId: team._id });
    });
    setState({ ...state, adminMenuOpen: false, saving: false, menuFor: null });
  };

  const changeName = async newName => {
    const team = state.menuFor;
    setState({ ...state, saving: true });
    guard(async () => {
      team.name = newName;
      await teamsStore.save(team);
    });
    setState({ ...state, adminMenuOpen: false, saving: false, menuFor: null });
    track('Team Name Changed', { category: 'Teams', teamId: team._id });
  };

  const changeDescription = async newDescription => {
    const team = state.menuFor;
    setState({ ...state, saving: true });
    guard(async () => {
      team.description = newDescription;
      await teamsStore.save(team);
    });
    setState({ ...state, adminMenuOpen: false, saving: false, menuFor: null });
    track('Team Description Changed', { category: 'Teams', teamId: team._id });
  };

  const viewTeam = team => history.push(`/teams/${team._id}`);
  const loading = tLoading || aLoading;
  const error = tError || aError;

  return (
    <PageContent className={classes.root}>
      { fullscreenLoader }
      <Helmet>
        <title>Teams</title>
      </Helmet>
      <Typography variant='h1' component='h1'>
        Teams
      </Typography>
      <PageSection variant='card'>
      {
        !loading && !error && teams && <>
          <div className={classes.pageSection}>
            <Button
              fullWidth
              className={classes.addButton}
              variant='outlined' size='large' color='primary'
              startIcon={<AddCircleOutlineIcon />}
              onClick={createNewTeam}
            >
              Create New Team
            </Button>
            {
              (teams.lead || []).map(team => (
                <TeamCard
                  key={team._id}
                  className={classes.card}
                  team={team}
                  ctaLabel='View Team'
                  onOpenMenu={showAdminDialog(team)}
                  onClickCta={viewTeam}
                />
              ))
            }

              {
                (teams.member || []).map(team => (
                  <TeamCard
                    key={team._id}
                    className={classes.card}
                    team={team}
                    onOpenMenu={showMemberDialog(team)}
                  />
                ))
              }
          </div>
        </>
      }
      </PageSection>

      <TeamCardAdminMenu
        team={state.menuFor}
        open={state.adminMenuOpen}
        onDelete={deleteTeam}
        onChangeName={changeName}
        onChangeDescription={changeDescription}
        disabled={state.saving}
        onClose={closeAdminDialog}
      />

      <TeamCardMemberMenu
        team={state.menuFor}
        open={state.memberMenuOpen}
        onLeave={leaveTeam}
        disabled={state.saving}
        onClose={closeMemberDialog}
      />
    </PageContent>
  );
});