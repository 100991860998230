import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { extendMoment } from 'moment-range';
import { makeStyles } from '@material-ui/core/styles';

import { PageSection, PageContent } from 'components/layout';
import { MonthSelector } from './components/month-selector.component';
import { TrackerCalendar } from './components/tracker-calendar.component';
import { SubHeader } from 'components/sub-header';
import { TrackerStore } from 'modules/tracker';
import { connectObserver, useStoreQuery } from 'utils/state';

const moment = extendMoment(Moment);
const getDate = (offset = 0) => moment().add(offset, 'months');

const useStyles = makeStyles(theme => ({
  selector: {
    marginTop: theme.spacing(0.5),
  },
  calendar: {
    marginTop: theme.spacing(2),
  },
}));

export const TrackerPage = connectObserver(
  'trackerStore',
)(({
  trackerStore
}) => {
  const classes = useStyles();
  const [ monthOffset, setMonthOffset ] = React.useState(0);
  const [ error, loading ] = useStoreQuery(trackerStore, 'fetch');
  const days = trackerStore.items$;
  const date = getDate(monthOffset);
  const hasNext = monthOffset < 0;
  const hasPrevious = true;
  const handleNext = () => hasNext && setMonthOffset(monthOffset + 1);
  const handlePrevious = () => hasPrevious && setMonthOffset(monthOffset - 1);

  return (
    <PageContent>
      <Helmet>
        <title>Mood Tracker</title>
      </Helmet>
      {
        loading && <div>Loading...</div>
      }
      {
        error && <div>Error</div>
      }
      {
        !loading && !error && days && <>
          <Typography variant='h1' gutterBottom>My Mood Tracker</Typography>
          <SubHeader>
            This is an overview of all the mood trackings you added
          </SubHeader>
          <PageSection variant='card'>
            <MonthSelector date={date} onNext={hasNext && handleNext}
              onPrevious={hasPrevious && handlePrevious} className={classes.selector} />
            <TrackerCalendar className={classes.calendar} date={date} dayData={days} />
          </PageSection>
        </>
      }
    </PageContent>
  );
});

TrackerPage.propTypes = {
  trackerStore: PropTypes.instanceOf(TrackerStore),
};
