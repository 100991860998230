import { find } from 'ramda';
import { toJS } from 'mobx';

import { CollectionStore } from 'modules/base';

export class NotebooksStore extends CollectionStore {
  baseUrl = '/notebooks';

  constructor(config) {
    super(config);

    this.authService = config.authService;
  }

  export(item) {
    const notebook = super.export(item);
    notebook.user = this.authService.currentUser$._id;
    return notebook;
  }

  import(item) {
    delete item.user;
    return super.import(item);
  }

  async forCoaching(coachingId) {
    let item = find(
      notebook => notebook.coaching._id === coachingId,
      this.items$
    );

    if (!item) {
      const response = await this.httpService.get(`${this.url}/coachings/${coachingId}`);
      item = this.import(response.body);
      this.items$.unshift(item);
      return item;
    }

    return item;
  }

  async savePage(page) {
    return this.httpService.put(`${this.url}/pages/${page._id}`, toJS(page));
  }
}
