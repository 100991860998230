import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MuiThumbUpIcon from '@material-ui/icons/ThumbUp';
import MuiThumbDownIcon from '@material-ui/icons/ThumbDown';
import clsx from 'clsx';
import { Helmet } from "react-helmet";
import { useParams, useHistory } from 'react-router-dom';
import { compose, path } from 'ramda';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { PageContent, PageSection } from 'components/layout';
import { LearningCard } from './components/learning-card.component';
import { CoachingsStore } from 'modules/coachings';
import { loginRequired } from 'modules/auth';
import { useSegment } from 'utils/analytics';
import { connectObserver, useStoreQuery } from 'utils/state';

const decorate = compose(loginRequired, connectObserver(
  'coachingsStore',
));

const IconButton = withStyles({
  label: {
    fontSize: '2rem',
  },
})(Button);

const ThumbUpIcon = withStyles({
  root: {
    fontSize: '2.5rem',
  }
})(MuiThumbUpIcon);

const ThumbDownIcon = withStyles({
  root: {
    fontSize: '2.5rem',
  }
})(MuiThumbDownIcon);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  section: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  imageContainer: {
    marginTop: 'calc(-1 * var(--page-padding))',
    marginLeft: 'calc(-1 * var(--page-padding))',
    marginRight: 'calc(-1 * var(--page-padding))',
    padding: 'var(--page-padding)',
    height: '35vh',
    backgroundImage: 'url(https://storage.googleapis.com/bestselfy-static/confetti.svg)',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  textBox: {
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
  },
  text: {
    padding: '2px 4px',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  learning: {
    marginTop: -36,
  },
  ratingActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  ratingActions: {
    margin: '48px auto',

    '@media(max-width: 320px)': {
      marginTop: 24,
      marginBottom: 24,
    }
  },
  iconButton: {
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  icon: {
    fill: theme.palette.text.disabled,
  },
  iconDislikeActive: {
    fill: theme.palette.warn.main
  },
  iconLikeActive: {
    fill: theme.palette.success.main
  },
}));

export const CoachingCompletionPage = decorate(({
  coachingsStore,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { track } = useSegment();
  const { id } = useParams();
  const [error, loading, coaching] = useStoreQuery(coachingsStore, 'get', {
    args: id,
  });

  const trackRating = score => track('Coaching Rated', {
    score,
    coaching: coaching.title,
    coachingId: coaching._id,
  });
  const onContinue = () => {
    if (typeof coaching.rating === 'number') {
      trackRating(coaching.rating);
      coachingsStore.save(coaching);
    }
    history.push('/dashboard');
  };
  const onLike = async () => {
    coaching.rating = 1;
  };
  const onDislike = async () => {
    coaching.rating = 0;
  };

  return <>
    <Helmet>
      Coaching Completed
    </Helmet>

    <PageContent className={classes.root} variant='no-background'>
      <PageSection className={classes.section}>
        {
          loading ? <div>Loading...</div> : null
        }
        {
          error ? <div>ERROR</div> : null
        }
        {
          !loading && !error && coaching && <>
            <div className={classes.imageContainer}>
              <div className={classes.textBox}>
                <Typography variant='h2' component='span' className={classes.text}>
                  Coaching Completed!
                </Typography>
                <Typography className={classes.text}>
                  Congratulations, you&apos;ve successfully completed this
                  coaching plan.
                </Typography>
              </div>
            </div>

            {
              path(['focusArea', 'learning'], coaching)
                ? <LearningCard className={classes.learning} coaching={coaching} />
                : null
            }

            <div className={classes.actions}>
              <div className={classes.ratingActionsContainer}>
                <div className={classes.ratingActions}>
                  <Typography variant='h3' component='h3'>
                    Rate this coaching plan
                  </Typography>
                  <div className={classes.buttonGroup}>
                    <IconButton onClick={onDislike}>
                      <ThumbDownIcon className={clsx(classes.icon, {
                        [classes.iconDislikeActive]: coaching.rating === 0,
                      })} />
                    </IconButton>
                    <IconButton className={classes.iconButton} onClick={onLike}>
                      <ThumbUpIcon className={clsx(classes.icon, {
                        [classes.iconLikeActive]: coaching.rating === 1,
                      })} />
                    </IconButton>
                  </div>
                </div>
              </div>
              <div className={classes.continueAction}>
                <Button variant='contained' onClick={onContinue} fullWidth size='large'>
                  Continue
                </Button>
              </div>
            </div>
          </>
        }
      </PageSection>
    </PageContent>
  </>;
});

CoachingCompletionPage.propTypes = {
  coachingsStore: PropTypes.instanceOf(CoachingsStore),
};
