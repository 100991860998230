import moment from 'moment';
import * as log from 'loglevel';
import { observable, makeObservable, computed } from 'mobx';

import { firebase } from 'modules/firebase';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;

class AuthService {
  httpService = null;
  refreshTimeout = null;
  _currentUser$ = observable.box();
  animationFrame = null;
  lastRender = null;

  constructor(config) {
    makeObservable(this, {
      _currentUser$: observable,
      currentUser$: computed,
    });

    this.httpService = config.httpService;

    firebase.auth().onAuthStateChanged(async user => {
      log.debug('AuthService#onAuthStateChanged', user);
      this.initialized$ = true;
      this.currentUser$ = user;
    });

    firebase.auth().onIdTokenChanged(async user => {
      log.debug('AuthService#onIdTokenChanged', user);
      if (user && !user.isAnonymous) {
        const { token, expirationTime } = await user.getIdTokenResult();
        this.httpService.token = token;
        this.scheduleTokenRefresh(expirationTime);
      } else {
        this.httpService.logout();
      }
    });

    window.requestAnimationFrame(this.checkLastRender);
  }

  /*
   * Forces a re-render when this app wasn't on a rendering thread for 10
   * minutes.
   */
  checkLastRender = now => {
    if (!this.lastRender) {
      this.lastRender = now;
    }
    if (now - this.lastRender > 10 * ONE_MINUTE) {
      window.location.reload();
    }
    this.lastRender = now;
    setTimeout(
      () => window.requestAnimationFrame(this.checkLastRender),
      ONE_MINUTE
    );
  };

  get isAuthenticated() {
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged(user => {
        resolve(Boolean(user && !user.isAnonymous));
      });
    });
  }

  get currentUser$() {
    return this._currentUser$.get();
  }
  set currentUser$(newValue) {
    this._currentUser$.set(newValue);
  }

  async register(credentials) {
    await this.httpService.post('/users/create', credentials);
    const user = await this.login(credentials.email, credentials.password);
    return { user };
  }

  async refreshIdToken() {
    log.debug('AuthService#refreshIdToken', new Date());
    if (this.currentUser$) {
      await this.currentUser$.getIdToken(true);
    }
  }

  scheduleTokenRefresh(expirationTime) {
    log.debug('AuthService#scheduleTokenRefresh', expirationTime);
    const exp = moment.utc(expirationTime);

    const timeout = exp.subtract(moment.utc()).subtract(5, 'minutes').valueOf();

    log.debug(
      'AuthService#scheduleTokenRefresh in',
      timeout,
      `(${ Math.ceil(timeout / ONE_MINUTE) }m)`
    );

    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(
      async () => await this.refreshIdToken(),
      timeout,
    );
  }

  async reloadCurrentUser() {
    if (!this.currentUser$) { return false; }
    await this.currentUser$.reload();
    this.currentUser$ = firebase.auth().currentUser;
  }

  async sendEmailVerificationMail(url) {
    if (!this.currentUser$) { return; }

    const actionCodeSettings = { url };
    await this.currentUser$.sendEmailVerification(actionCodeSettings);
  }

  async login(email, password) {
    const user = await firebase.auth().signInWithEmailAndPassword(email, password);
    await this.isAuthenticated;
    return user;
  }

  async logout() {
    await firebase.auth().signOut();
    await this.isAuthenticated;
  }

  async updateEmail(newEmail, password) {
    await this.login(this.currentUser$.email, password);
    await this.currentUser$.verifyBeforeUpdateEmail(newEmail);
  }

  async updatePassword(code, password) {
    await firebase.auth().confirmPasswordReset(code, password);
  }

  async verifyEmail(code, variant = null) {
    return this.httpService.post('/users/verify-email', { code, variant });
  }
}

export {
  AuthService
};
