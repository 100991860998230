import React from 'react';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import BuildIcon from '@material-ui/icons/BuildOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import { toJS } from 'mobx';
import { Typography, makeStyles, withStyles } from '@material-ui/core';
import { take, takeLast, compose, reverse, sortBy, prop } from 'ramda';
import { useHistory } from 'react-router';

import { PlaceholderCard } from './placeholder.card';
import { ColorHeaderCard } from 'components/cards';
import {
  getFeature,
  getAssessmentForConfig,
} from 'utils/cms';
import { connectObserver, useStoreQuery } from 'utils/state';

const featuredInProfile = getFeature('assessment', 'leadership-skills', 'profile');
const byScore = compose(reverse, sortBy(prop('normalizedScore')));

const Result = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  header: {
    textAlign: 'center',
    fontSize: '0.825rem',
  },
  headerStrength: {
    backgroundColor: theme.palette.success.main,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  icon: {
    width: 24,
    color: theme.palette.blue[400],
  },
  text: {
    flexGrow: 1,
  },
  success: {
    color: theme.palette.success.main,
  },
}))(({
  classes,
  answers,
}) => {
  const strengths = take(2, answers);
  const improves = takeLast(2, answers);

  return (
    <div className={classes.root}>
      <ColorHeaderCard
        headerTitle='Strengths'
        headerClassName={clsx(classes.header, classes.headerStrength)}
      >
        <div className={classes.cardContent}>
          {
            strengths.map(str => (
              <div key={str._id} className={classes.cardRow}>
                <AddIcon className={clsx(classes.icon, classes.success)} />
                <Typography className={classes.text} variant='body1'>
                  {str.classification.name}
                </Typography>
              </div>
            ))
          }
        </div>
      </ColorHeaderCard>

      <ColorHeaderCard
        headerTitle='Improvements'
        headerClassName={clsx(classes.header)}
      >
        <div className={classes.cardContent}>
          {
            improves.map(str => (
              <div key={str._id} className={classes.cardRow}>
                <BuildIcon className={clsx(classes.icon)} />
                <Typography className={classes.text} variant='body1'>
                  {str.classification.name}
                </Typography>
              </div>
            ))
          }
        </div>
      </ColorHeaderCard>
    </div>
  );
});

const decorate = connectObserver(
  'assessmentConfigurationStore',
  'assessmentAnswersStore',
);

const useStyles = makeStyles({
  root: {},
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerIcon: {
    margin: 'auto 0',
  },
  clickeable: {
    cursor: 'pointer',
  },
});

export const LeadershipSkillsSection = decorate(({
  assessmentConfigurationStore,
  assessmentAnswersStore,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [error, loading, configs] = useStoreQuery(assessmentConfigurationStore, 'fetch');

  const [state, setState] = React.useState({
    answer: null,
    config: null,
    feature: null,
  });

  const handleClick = React.useCallback(() => {
    if (state.answer) {
      history.push(`/assessments/result/${state.answer._id}?redirectTo=/profile`);
    } 
  });

  React.useEffect(() => {
    if (!configs) { return; }
    const feature = featuredInProfile(configs);
    if (!feature) { return; }
    const config = getAssessmentForConfig(feature)(configs);

    (async function () {
      const answers = await assessmentAnswersStore.fetch(config.assessment);
      setState(prev => ({ ...prev, config, feature, answer: answers[0] }));
    })();
  }, [configs]);

  return <div className={classes.root}>
    <Typography
      variant='h2'
      component='h2'
      onClick={handleClick}
      className={clsx(classes.sectionHeader, {
        [classes.clickeable]: !!state.answer,
      })}
    >
      <span>Leadership Skills</span>
      {
        !!state.answer
          ? <ArrowForwardIcon className={classes.headerIcon} />
          : null
      }
    </Typography>
    {
      state.answer
        ? <Result answers={byScore(state.answer?.result || [])} />
        : <PlaceholderCard
            title='5 minute assessment'
            text='Learn how your skills measure up when it comes to leadership.'
            ctaUrl={`/assessments/${state.config?.assessment}?redirectTo=/profile`}
          />

    }
  </div>;
});
