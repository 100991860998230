import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router';
import { compose } from 'ramda';

import { PageContent, PageSection } from 'components/layout';
import { CoachingNudgeEntry } from 'app/pages/teams/components';
import { loginRequired } from 'modules/auth';
import { connectObserver, useStoreQuery, useLoaders } from 'utils/state';
import { BottomStickyControls } from 'components/bottom-sticky';

const RATING = {
  0: 'I need to see more...',
  1: 'Good effort',
  2: 'Amazing',
};

const LargeAvatar = withStyles({
  root: {
    width: 65,
    height: 65,
    fontSize: '1.5rem',
  },
})(Avatar);

const TeamMemberHeader = withStyles(theme => ({
  title: {
    marginBottom: theme.spacing(0),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(({ initials, name, email, role, avatar, classes }) => (
  <Box display='flex' mb={3}>
    <Box display='flex' flexDirection='column' alignItems='center' mr={2}>
      <LargeAvatar src={avatar}>{initials}</LargeAvatar>
    </Box>
    <Box display='flex' flexDirection='column' justifyContent='center'>
      <Typography className={classes.title} variant='h2' component='h1'>
        {name || email}
      </Typography>
      <Typography component='div'>{role || 'Team Member'}</Typography>
    </Box>
  </Box>
));

const decorate = compose(loginRequired, connectObserver(
  'completedCoachingNudgesStore',
  'teamsStore',
));

const useStyles = makeStyles(theme => ({
  nudge: {
    marginBottom: theme.spacing(3),
  },
}));

export const ArchivedNudgesPage = decorate(({
  teamsStore,
  completedCoachingNudgesStore,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { teamId, memberId } = useParams();

  const [tError, tLoading, team] = useStoreQuery(teamsStore, 'get', {
    args: [teamId],
  });

  const member = React.useMemo(() => {
    if (!team) { return null; }
    return team.members.find(m => m._id === memberId);
  }, [team]);

  const [ cError, cLoading, completedNudges ] = useStoreQuery(
    completedCoachingNudgesStore,
    'fetch',
    {
      args: [member?.account?._id],
      refresher: [member],
    }
  );

  const { initials, name, email, role, avatar } = React.useMemo(() => {
    if (!member) { return {}; }
    const { firstName, lastName, user, role, avatar } = member.account;
    const name = (firstName && lastName)
      ? `${firstName} ${lastName}`
      : user.email;
    const initials = (firstName && lastName)
      ? `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
      : user.email.charAt(0).toUpperCase();
    return { name, initials, email: user.email, role, avatar };
  }, [member]);

  const { fullscreenLoader } = useLoaders([team]);

  return (
    <PageContent className={classes.root}>
      {fullscreenLoader}
      <Helmet>
        <title>{name || email || 'Team Member'}</title>
      </Helmet>
      <TeamMemberHeader initials={initials} name={name} email={email} role={role} avatar={avatar} />
      <PageSection variant='card' bottomSticky>
        {
          completedNudges
            ? completedNudges.map(nudge => (
                <CoachingNudgeEntry
                  nudge={nudge}
                  rating={RATING[nudge.rating]}
                  className={classes.nudge}
                  key={nudge._id}
                />
              ))
            : null
        }
      </PageSection>

      <BottomStickyControls>
        <Button
          fullWidth
          variant='contained'
          size='large'
          color='primary'
          onClick={() => history.push(`/teams/${teamId}/members/${memberId}`)}
        >
          Back
        </Button>
      </BottomStickyControls>
    </PageContent>
  );
});
